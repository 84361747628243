import React, { useEffect, useState } from "react";
import { downloadFile } from "../../functions/rest";

import "./customerbar.css"
import { Badge, Image, Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const CustomerBar = (props) => {
    const [customerImage, setCustomerImage] = useState(null);

    useEffect(() => {
        if(props.fileList !== null) {
            props.fileList.filter((file) => file.filename.startsWith(props.user.email)).map((file) => 
            downloadFile(props.user.token, file.path)
            .then((file) => setCustomerImage(file)));
        }
    }, [props.user, props.fileList]);

    if(customerImage === null) return null;

    return (
        <div className="customerbar">
            <div className="customerImage">
                <Image src={`data:image/jpeg;base64,${customerImage.file}`} rounded />
            </div>
            <div className="textContent">
                <div className="email">
                    <Navbar>
                        <Navbar.Toggle />
                        <Navbar.Collapse>
                            <NavDropdown title={props.user.email} id="userDropdown">
                                <Link to="/user"><NavDropdown.ItemText>Account</NavDropdown.ItemText></Link>
                                <NavDropdown.Divider />
                                <Link to="/logout"><NavDropdown.ItemText>Log out</NavDropdown.ItemText></Link>
                            </NavDropdown>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
            <div className="bell">
                <FontAwesomeIcon className="bellIcon" icon={faBell} />
                <Badge variant="light">0</Badge>
            </div>
        </div>
    );
}

export default CustomerBar;