import { verifyToken } from "./jwt";

export function performLogin(username, password) {
    const loginResult = fetch(`https://api.iotnett.no/authentication/authenticate.v2`, {
        method: 'POST',
        headers: {
            "Content-Type":"application/json"
        },
        body: JSON.stringify({
            "username": username,
            "password": password
        })
    })
    .then(response => response.json())
    .then(data => {
        if(verifyToken(data.token)) {
            return data.token;
        }
        console.log("Failed to verify token from server, when logging in");
        return false;
    })
    .catch(error => {
        console.log("We were unable to authenticate your session. Please verify your details and try again. Error was: ");
        console.log(error);
        return false;
    } );
    return loginResult;
}

export function renewToken(token) {
    const newToken = fetch(`https://api.iotnett.no/authentication/renewToken`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "jwt":token
        }
    })
    .then(response => response.json())
    .then(data => {
        if(verifyToken(data.token)) {
            return data.token;
        }
        console.log("Failed to verify token when refreshing");
        return false;
    })
    .catch(error => {
        console.log("We were unable to renew your token. Please log out and try again. Error was: " + error);
        return false;
    } );
    return newToken;
}

export function downloadFile(token, path) {
    const encodedValue = encodeURIComponent(path)
    const iotFile = fetch(`https://api.iotnett.no/files/download.v2?filePath=${encodedValue}`, {
        method: "POST",
        headers: {
            "Content-Type":"application/json",
            "jwt":token
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve file. Error was: " + error);
        return null;
    } );
    return iotFile;
}

export function getFileList(token) {
    const fileList = fetch(`https://api.iotnett.no/files/list`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "jwt":token
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve file list. Error was: " + error);
        return null;
    } );
    return fileList;
}

export function getUserInformation(token) {
    const userInfo = fetch(`https://api.iotnett.no/companies/user`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "jwt":token
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve user info. Error was: " + error);
        return null;
    } );
    return userInfo;
}

export function getLastMeasurement(deviceId, token) {
    const lastMeasurement = fetch(`https://api.iotnett.no/devices/device/${deviceId}/measurements/last`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "jwt":token
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve user info. Error was: " + error);
        return null;
    } );
    return lastMeasurement;
}

export function getMeasurements(deviceId, token, days) {
    const startTime = (Date.now()-(60000*60*24*days));
    const lastMeasurement = fetch(`https://api.iotnett.no/devices/device/${deviceId}/measurements.v2?startTime=${startTime}`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "jwt":token
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve user info. Error was: " + error);
        return null;
    } );
    return lastMeasurement;
}

export function getMeasurementsWithinRange(deviceId, token, startTime, endTime) {
    const lastMeasurement = fetch(`https://api.iotnett.no/devices/device/${deviceId}/measurements.v2?startTime=${startTime}&endTime=${endTime}`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "jwt":token
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve user info. Error was: " + error);
        return null;
    } );
    return lastMeasurement;
}

export function getDeviceList(token) {
    const deviceList = fetch(`https://api.iotnett.no/devices/devices`, {
        method: "GET",
        headers: {
            "Content-Type":"application/json",
            "jwt":token
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to retrieve devices. Error was: " + error);
        return null;
    } );
    return deviceList;
}

export function patchDevice(token, device) {
    const patchDevice = fetch(`https://api.iotnett.no/devices/device`, {
        method: "PATCH",
        headers: {
            "Content-Type":"application/json",
            "jwt": token
        },
        body: JSON.stringify(device)
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to patch device. Error was: " + error);
        return null;
    } );
    return patchDevice;
}

export function patchAlarm(token, alarm) {
    const patchAlarm = fetch(`https://api.iotnett.no/alarms/alarm`, {
        method: "PATCH",
        headers: {
            "Content-Type":"application/json",
            "jwt": token
        },
        body: JSON.stringify(alarm)
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to patch alarm. Error was: " + error);
        return null;
    } );
    return patchAlarm;
}

export function patchUser(token, user) {
    const patchUser = fetch(`https://api.iotnett.no/companies/user`, {
        method: "PATCH",
        headers: {
            "Content-Type":"application/json",
            "jwt": token
        },
        body: JSON.stringify(user)
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to patch user. Error was: " + error);
        return null;
    } );
    return patchUser;
}

export function postRequestOneTimeCode(email) {
    const otcRequest = fetch(`https://api.iotnett.no/companies/user/resetPassword?email=${email}`, {
        method: "POST",
        headers: {
            "Content-Type":"application/json"
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to request one time code. Error was: " + error);
        return null;
    } );
    return otcRequest;
}

export function patchSetPassword(email, requestContent) {
    const setPasswordRequest = fetch(`https://api.iotnett.no/companies/user/resetPassword?email=${email}`, {
        method: "PATCH",
        headers: {
            "Content-Type":"application/json"
        },
        body: JSON.stringify(requestContent)
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.log("Unable to set password. Error was: " + error);
        return null;
    } );
    return setPasswordRequest;
}