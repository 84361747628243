import React from 'react'
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useCurrentPng } from 'recharts-to-png';
import PDFModal from '../PDFModal/PDFModal';
import StatisticsCard from '../StatisticsCard/StatisticsCard';

const ExploraPMChart = (props) => {
    const temperatureData = [];
    const humidityData = [];
    const pmData = [];

    const temperatureStatistics = {
        pointCounter: 0,
        totalValue: null,
        highest: null,
        lowest: null
    };
    const humidityStatistics = {
        pointCounter: 0,
        totalValue: null,
        highest: null,
        lowest: null
    };

    const [getTemperaturePng, { ref: tempChart }] = useCurrentPng();
    const [getHumidityPng, { ref: humidityChart }] = useCurrentPng();
    const [getPMChartPng, { ref: pmChart }] = useCurrentPng();

    props.chartData["values"].map(value => {
        var humidity = value[props.chartData["columns"].indexOf("humidity")];
        var temperature = value[props.chartData["columns"].indexOf("temperature")];
        var pm10_0 = value[props.chartData["columns"].indexOf("pm10_0")];
        var pm1_0 = value[props.chartData["columns"].indexOf("pm1_0")];
        var pm2_5 = value[props.chartData["columns"].indexOf("pm2_5")];

        var temperatureObject = {
            Time: value[0],
            Temperature: temperature
        }
        var humidityObject = {
            Time: value[0],
            Humidity: humidity
        }
        var pmObject = {
            Time: value[0],
            PM10: pm10_0,
            PM2_5: pm2_5,
            PM1_0: pm1_0
        }
        temperatureData.push(temperatureObject);
        humidityData.push(humidityObject);
        pmData.push(pmObject);

        temperatureStatistics["pointCounter"] = temperatureStatistics["pointCounter"] + 1;
        temperatureStatistics["totalValue"] = temperatureStatistics["totalValue"] + temperature;
        if(temperatureStatistics["highest"] == null || temperatureStatistics["highest"] < temperature) temperatureStatistics["highest"] = temperature;
        if(temperatureStatistics["lowest"] == null || temperatureStatistics["lowest"] > temperature) temperatureStatistics["lowest"] = temperature;

        humidityStatistics["pointCounter"] = humidityStatistics["pointCounter"] + 1;
        humidityStatistics["totalValue"] = humidityStatistics["totalValue"] + humidity;
        if(humidityStatistics["highest"] == null || humidityStatistics["highest"] < humidity) humidityStatistics["highest"] = humidity;
        if(humidityStatistics["lowest"] == null || humidityStatistics["lowest"] > humidity) humidityStatistics["lowest"] = humidity;

        return value;
    });
    return (
        <div>
            <PDFModal title="Export PDF" startTime={props.pdfStartTime} endTime={props.pdfEndTime} friendlyName={props.device.friendlyName} images={[
                {
                    key: "Temperature",
                    image: getTemperaturePng,
                    data: temperatureData
                }, 
                {
                    key: "Humidity",
                    image: getHumidityPng,
                    data: humidityData 
                }, 
                {
                    key: "Particulate Matter",
                    image: getPMChartPng,
                    data: pmData
                }
                ]} data={[temperatureData, humidityData, pmData]} />
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    ref={tempChart}
                    data={temperatureData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"Time"}></XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Temperature" type="monotone" dataKey={"Temperature"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <StatisticsCard 
            header={"Temperature Statistics"}
            min={temperatureStatistics["lowest"]}
            max={temperatureStatistics["highest"]}
            points={temperatureStatistics["pointCounter"]}
            average={temperatureStatistics["totalValue"]/temperatureStatistics["pointCounter"]}
            />
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    ref={humidityChart}
                    data={humidityData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"Time"}></XAxis>
                    <YAxis type="number" domain={[0, 100]}/>
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Humidity" type="monotone" dataKey={"Humidity"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <StatisticsCard 
            header={"Humidity Statistics"}
            min={humidityStatistics["lowest"]}
            max={humidityStatistics["highest"]}
            points={humidityStatistics["pointCounter"]}
            average={humidityStatistics["totalValue"]/humidityStatistics["pointCounter"]}
            />
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    ref={pmChart}
                    data={pmData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"Time"}></XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="PM 10.0" type="monotone" dataKey={"PM10"} stroke="#ff7300" yAxisId={0} dot={false} />
                    <Line name="PM 2.5" type="monotone" dataKey={"PM2_5"} stroke="#000dff" yAxisId={0} dot={false} />
                    <Line name="PM 1.0" type="monotone" dataKey={"PM1_0"} stroke="#00fff3" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}
export default ExploraPMChart;