import { faAt, faBell, faEnvelope, faShieldAlt, faSms } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { patchUser } from '../../functions/rest';
import { SET_USER } from '../../reducers/User';
import { useToasts } from 'react-toast-notifications';

import "./User.css"

const userSelector = state => state.User;

const User = () => {
    const dispatch = useDispatch();

    const user = useSelector(userSelector);

    const [contactEmails, setContactEmails] = useState(null);
    const [contactNumbers, setContactNumbers] = useState(null);
    const [fcmTokens, setFcmTokens] = useState(null);

    const [pushNotificationEnabled, setPushNotificationEnabled] = useState(null);
    const [smsNotificationEnabled, setSmsNotificationEnabled] = useState(null);
    const [emailNotificationEnabled, setEmailNotificationEnabled] = useState(null);

    const [disableSaveButton, setDisableSaveButton] = useState(true);

    const { addToast } = useToasts();

    useEffect(() => {
        setContactEmails(user.contactEmails);
        setContactNumbers(user.contactNumbers);
        setFcmTokens(user.fcmTokens);

        setPushNotificationEnabled(user.pushAlertingEnabled);
        setSmsNotificationEnabled(user.smsAlertingEnabled);
        setEmailNotificationEnabled(user.emailAlertingEnabled);

    }, [user.contactEmails, user.contactNumbers, user.fcmTokens,user.pushAlertingEnabled,user.smsAlertingEnabled,user.emailAlertingEnabled]);

    useEffect(() => {
        if(
            user.contactEmails === contactEmails &&
            user.contactNumbers === contactNumbers &&
            user.fcmTokens === fcmTokens &&
            user.pushAlertingEnabled === pushNotificationEnabled &&
            user.smsAlertingEnabled === smsNotificationEnabled &&
            user.emailAlertingEnabled === emailNotificationEnabled
            ) {
                setDisableSaveButton(true);
        } else {
            if(!user.smsAlertingEnabled && smsNotificationEnabled) {
                addToast("SMS is a paid service and extra costs may apply", {appearance: 'warning'});
            }
            setDisableSaveButton(false);
        }
    }, [user.contactEmails, user.contactNumbers, user.fcmTokens, user.pushAlertingEnabled, user.smsAlertingEnabled, user.emailAlertingEnabled, contactEmails, contactNumbers, fcmTokens, pushNotificationEnabled, smsNotificationEnabled, emailNotificationEnabled, addToast]);

    const saveUser = () => {
        const newUser = {
            ...user,
            contactEmails: contactEmails,
            contactNumbers: contactNumbers,
            fcmTokens: fcmTokens,
            pushAlertingEnabled: pushNotificationEnabled,
            smsAlertingEnabled: smsNotificationEnabled,
            emailAlertingEnabled: emailNotificationEnabled
        }
        patchUser(user.token, newUser).then((result) => {
            if(result) {
                dispatch({
                    type: SET_USER,
                    payload: result,
                    token: user.token
                });
                addToast("User successfully patched", { appearance: 'success' });
            } else {
                addToast("Failed to patch user", { appearance: 'error' });
            }
        });
    }

    return (
        <div className="pageContainer">
            <div className="userForm">
                <InputGroup className="mb-1">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faAt}></FontAwesomeIcon>
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="E-mail"
                        aria-label="E-mail"
                        aria-describedby="basic-addon1"
                        value={user.email || ""}
                        readOnly
                    />
                    <InputGroup.Append>
                    <InputGroup.Text className="customFooter" id="basic-addon1">
                        Username
                    </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-1">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faShieldAlt}></FontAwesomeIcon>
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="E-mail"
                        aria-label="E-mail"
                        aria-describedby="basic-addon1"
                        value={user.accessLevel || ""}
                        readOnly
                    />
                    <InputGroup.Append>
                    <InputGroup.Text className="customFooter" id="basic-addon1">
                        Access level
                    </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-1">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="Contact e-mails"
                        aria-label="Contact e-mails"
                        aria-describedby="basic-addon1"
                        value={contactEmails || ""}
                        as="textarea"
                        onChange={(event) => {setContactEmails(event.target.value.replace('\n',',').split(","))}}
                    />
                    <InputGroup.Append>
                    <InputGroup.Text className="customFooter" id="basic-addon1">
                        E-mails
                    </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-1">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faSms}></FontAwesomeIcon>
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="Contact numbers"
                        aria-label="Contact numbers"
                        aria-describedby="basic-addon1"
                        value={contactNumbers || ""}
                        as="textarea"
                        onChange={(event) => {setContactNumbers(event.target.value.replace('\n',',').split(","))}}
                    />
                    <InputGroup.Append>
                    <InputGroup.Text className="customFooter" id="basic-addon1">
                        Numbers
                    </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
                <InputGroup className="mb-1">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faBell}></FontAwesomeIcon>
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="FCM Tokens"
                        aria-label="FCM Tokens"
                        aria-describedby="basic-addon1"
                        value={fcmTokens || ""}
                        as="textarea"
                        onChange={(event) => {setFcmTokens(event.target.value.replace('\n',',').split(","))}}
                    />
                    <InputGroup.Append>
                    <InputGroup.Text className="customFooter" id="basic-addon1">
                        Tokens
                    </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
                <InputGroup className="switchContainer">
                    <div className="switchDiv">
                        <Form.Switch
                            label={pushNotificationEnabled ? "Push enabled" : "Push disabled"}
                            id="pushSwitch"
                            checked={pushNotificationEnabled || ''}
                            onChange={() => setPushNotificationEnabled(!pushNotificationEnabled)}
                        />
                        <Form.Switch
                            label={emailNotificationEnabled ? "Email enabled" : "Email disabled"}
                            id="emailSwitch"
                            checked={emailNotificationEnabled || ''}
                            onChange={() => setEmailNotificationEnabled(!emailNotificationEnabled)}
                        />
                        <Form.Switch
                            label={smsNotificationEnabled ? "SMS enabled" : "SMS disabled"}
                            id="smsSwitch"
                            checked={smsNotificationEnabled | ''}
                            onChange={() => setSmsNotificationEnabled(!smsNotificationEnabled)}
                        />
                    </div>
                </InputGroup>
                <Button disabled={disableSaveButton} variant="success" onClick={saveUser}>
                    Save
                </Button>
            </div>
        </div>
    )
}

export default User;