import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import AlarmBar from './AlarmBar';

const AlarmConfig = (props) => {
    return (
        <Modal size="lg" show={props.visible} onHide={props.close}>
            <Modal.Header closeButton>
            <Modal.Title>{props.device.friendlyName} ({props.device.hardwareSerial}) - Alarm configuration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    props.device.alarms.map(alarm => {
                        return(
                            <AlarmBar key={"alarm_"+props.device.id+"_"+alarm.identifier} alarm={alarm} token={props.token} />
                        )
                    })
                }
            <div>
            </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={props.close}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default AlarmConfig;