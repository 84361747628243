import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import LAIRDChart from './LAIRDChart';
import LDS1Chart from './LDS1Chart';
import LHT65Chart from './LHT65Chart'
import LSE01Chart from './LSE01Chart';
import LWS1Chart from './LWS1Chart';
import NBSN9Chart from './NBSN9Chart';
import CalendarModal from '../CalendarModal/CalendarModal.js'
import LNS1Chart from './LNS1Chart';
import ExploraPMChart from './ExploraPMChart';
import EfentoChart from './EfentoChart';

const ChartDisplay = (props) => {

    const [pdfStartTime, setPdfStartTime] = useState(null);
    const [pdfEndTime, setPdfEndTime] = useState(null);

    const updateDate = (startTime, endTime) => {
        setPdfStartTime(startTime);
        setPdfEndTime(endTime);
        props.refreshChartData(startTime,endTime);
    }

    const getCharts = () => {
        if(props.chartData === null) {
            return (
                <div>No chartdata available</div>
            )
        } else {
            // props.devId = "LHT65-XXX"
            if(props.device.appId === "dragino-lht") {
                return <LHT65Chart chartData={props.chartData} device={props.device} pdfStartTime={pdfStartTime} pdfEndTime={pdfEndTime} />
            } else if(props.device.appId === "nbsn95") {
                return <NBSN9Chart chartData={props.chartData} device={props.device} pdfStartTime={pdfStartTime} pdfEndTime={pdfEndTime}/>
            } else if(props.device.appId === "laird-sentrius") {
                return <LAIRDChart chartData={props.chartData} device={props.device} pdfStartTime={pdfStartTime} pdfEndTime={pdfEndTime}/>
            } else if(props.device.appId === "dragino-lns") {
                return <LNS1Chart chartData={props.chartData} device={props.device} pdfStartTime={pdfStartTime} pdfEndTime={pdfEndTime}/>
            } else if(props.device.appId === "dragino-lwl01") { 
                return <LWS1Chart chartData={props.chartData} device={props.device} pdfStartTime={pdfStartTime} pdfEndTime={pdfEndTime}/>
            }  else if(props.device.appId === "dragino-lds01") { 
                return <LDS1Chart chartData={props.chartData} device={props.device} pdfStartTime={pdfStartTime} pdfEndTime={pdfEndTime}/>
            } else if(props.device.appId === "dragino-lds1-lws1") {
                if(props.device.devId.startsWith("lds")) {
                    return <LDS1Chart chartData={props.chartData} device={props.device} pdfStartTime={pdfStartTime} pdfEndTime={pdfEndTime}/>
                } else {
                    return <LWS1Chart chartData={props.chartData} device={props.device} pdfStartTime={pdfStartTime} pdfEndTime={pdfEndTime}/>
                }
            }  else if(props.device.appId === "dragino-lse01") {
                return <LSE01Chart chartData={props.chartData} device={props.device} pdfStartTime={pdfStartTime} pdfEndTime={pdfEndTime}/>
            } else if(props.device.appId === "senseair-explora-pm" ) {
                return <ExploraPMChart chartData={props.chartData} device={props.device} pdfStartTime={pdfStartTime} pdfEndTime={pdfEndTime} />
            } else if(props.device.appId === "efento-th") {
                return <EfentoChart chartData={props.chartData} device={props.device} pdfStartTime={pdfStartTime} pdfEndTime={pdfEndTime}/>
            }
            else {
                return (
                    <div>No charts available for this device type yet</div>
                )
            }
        }
    }
    return (
        <Modal size="xl" show={props.visible} onHide={props.close}>
            <Modal.Header closeButton>
            <Modal.Title>
                {props.device.friendlyName} ({props.device.hardwareSerial})
                <CalendarModal selectRange={true} onChange={updateDate} title={"Please select range to graph"} />
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    getCharts()
                }
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={props.close}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default ChartDisplay;