import React, { useEffect, useState } from 'react'

import "./PDFModal.css"

import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from 'react-bootstrap';
import { PDFViewer, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

const PDFModal = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [timespanDisplay, setTimespanDisplay] = useState("Last 24 hours");
    const [pdfDocument, setPdfDocument] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const formatDate = (date) => {
        return date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
    }

    useEffect(() => {
        if(isLoaded) return;
        if(props.startTime !== null && props.endTime !== null) {
            setTimespanDisplay(formatDate(props.startTime) + " - " + formatDate(props.endTime));
        } else {
            setTimespanDisplay("Last 24 hours");
        }
    }, [props.startTime, props.endTime, isLoaded]);

    useEffect(() => {
        if(isLoaded) return;

        const styles = StyleSheet.create({
            page: {
              flexDirection: 'column'
            },
            section: {
              margin: 10,
              padding: 10,
              flexGrow: 1
            },
            frontPage: {
                flexGrow: 0,
                textAlign: "center",
                paddingBottom: "20px"
            },
            table: { display: "table", width: "auto", borderStyle: "solid", borderWidth: 1, borderRightWidth: 0, borderBottomWidth: 0 }, 
            tableRow: { margin: "auto", flexDirection: "row" }, 
            tableCol: { width: "25%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 }, 
            tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
          });
        setPdfDocument(
            <Document key={"PdfDocument_"+props.friendlyName}>
                <Page size="A4" style={styles.page}>
                    <View style={styles.frontPage}>
                        <Image src={'/logo.png'} alt={"Company Logo"} />
                    </View>
                    <View style={styles.frontPage}>
                        <Text>Report for {props.friendlyName}</Text>
                    </View>
                    <View style={styles.frontPage}>
                        <Text>{timespanDisplay}</Text>
                    </View>
                    <View style={styles.frontPage}>
                        <Text>Generated { formatDate(new Date()) } </Text>
                    </View>
                </Page>
                {
                    props.images.map(imageObject => {
                        return (
                            <Page>
                                <View>
                                    <Image src={imageObject.image} key={"image_"+imageObject.key}/>
                                </View>
                                <View style={styles.table}>
                                    <View style={styles.tableRow} key={"table_descriptor"}> 
                                        <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Prefix</Text> 
                                        </View> 
                                        <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Value</Text> 
                                        </View> 
                                        <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Timestamp</Text> 
                                        </View> 
                                    </View>
                                    {
                                        imageObject.data.map((obj) => {
                                            return (
                                                <View style={styles.tableRow} key={imageObject.key +"_descriptor_"+obj.Time+"_"+Math.random()}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>{imageObject.key}</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        {
                                                            imageObject.key === "Particulate Matter" ?
                                                                <Text style={styles.tableCell}>
                                                                    <Text>
                                                                        PM 1.0  - {obj["PM1_0"]}
                                                                        {'\n'}
                                                                        PM 2.5  - {obj["PM2_5"]}
                                                                        {'\n'}
                                                                        PM 10.0 - {obj["PM10"]}
                                                                    </Text>
                                                                </Text> 
                                                                :
                                                                <Text style={styles.tableCell}>{obj[imageObject.key]}</Text> 
                                                        }
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                    <Text style={styles.tableCell}>{obj.Time}</Text> 
                                                    </View> 
                                                </View> 
                                            )
                                        })
                                    }
                                </View>
                            </Page>
                        )
                    })
                }
            </Document>
        );
        setIsLoaded(true);
    }, [props.images, props.friendlyName, timespanDisplay, props.data, isLoaded]);

    return (
        <div className={"containerDiv"}>
            {
                showModal ?
                <Button variant="secondary">
                    PDF is generating...
                </Button>
                :
                <Button variant="secondary" onClick={() => setShowModal(true)}>
                    Export PDF <FontAwesomeIcon icon={faFilePdf} />
                </Button>
            }
            <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                <Modal.Title>
                    {props.title}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"pdfContainer"}> 
                        <PDFViewer width="100%" height="800px">
                            {pdfDocument}
                        </PDFViewer>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default PDFModal;