export const SET_FILE_LIST = "SET_FILE_LIST";

export default function reducer(state = {
    fileList: []
}, action) {
    switch (action.type) {
        case SET_FILE_LIST:
            return {
                ...state,
                fileList: action.payload
            }
        default:
            return state;
    }
}