import { faAt, faLock, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import './resetPassword.css';
import logo from './LogoIcon.png'
import { useToasts } from 'react-toast-notifications';
import { patchSetPassword, postRequestOneTimeCode } from '../../functions/rest';
import { Link } from 'react-router-dom';

const ResetPasswordPage = () => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [email, setEmail] = useState("");
    const [oneTimeCode, setOneTimeCode] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [codeRequested, setCodeRequested] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [emailButtonDisabled, setEmailButtonDisabled] = useState(true);
    const [passwordSuccessfullyUpdated, setPasswordSuccessfullyUpdated] = useState(false);

    const requestCode = () => {
        postRequestOneTimeCode(email).then(result => {
            console.log(result);
            if(result.success) {
                setCodeRequested(true);
                addToast("E-mail verified", { appearance: "success" });
            } else {
                if(result.message === "Password reset has already been started") {
                    setCodeRequested(true);
                    addToast("Reset in progress - continuing", { appearance: "success" });
                } else {
                    setCodeRequested(false);
                    addToast("Unable to request one time code", { appearance: "error" });
                }
            }
        });
    }

    const patchPassword = () => {
        const requestContent = {
            "resetCode": oneTimeCode,
            "newPassword": password
        }
        patchSetPassword(email, requestContent).then(result => {
            if(result.success) {
                setPasswordSuccessfullyUpdated(true);
            } else {
                addToast("Failed to reset password", { appearance: "error" });
            }
        });
    }

    useEffect(() => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ( re.test(email) ) {
            setEmailButtonDisabled(false);
        } else {
            setEmailButtonDisabled(true);
        }
    }, [email]);

    useEffect(() => {
        if(password !== passwordRepeat || password === "" || passwordRepeat === "" || oneTimeCode === "" || password.length < 6) {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
    }, [password, passwordRepeat, oneTimeCode]);

    if(passwordSuccessfullyUpdated) {
        return (
            <div className="resetForm">
                <img src={logo} alt="Company Logo" height="300" width="300"></img>
    
                <div>
                    Your password has been successfully updated!
                </div>
                <div>
                    <Link className="link" to="/">Proceed to login</Link>
                </div>
            </div>
        )
    } else if(!codeRequested) {
        return (
            <div className="resetForm">
                <img src={logo} alt="Company Logo" height="300" width="300"></img>
    
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faAt}></FontAwesomeIcon>
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                    onChange={ (event) => setEmail(event.target.value) }
                    placeholder="Account e-mail"
                    aria-label="Account e-mail"
                    aria-describedby="basic-addon1"
                    value={email}
                    />
                </InputGroup>
    
                <Button variant="primary" className="loginButton" disabled={emailButtonDisabled} onClick={() => {
                    requestCode();
                }}>Request One Time Code</Button>
            </div>
        )
    } else {
        return (
            <div className="resetForm">
                <img src={logo} alt="Company Logo" height="300" width="300"></img>
    
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faAt}></FontAwesomeIcon>
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                    onChange={ (event) => setEmail(event.target.value) }
                    placeholder="Account e-mail"
                    aria-label="Account e-mail"
                    aria-describedby="basic-addon1"
                    value={email}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faStopwatch}></FontAwesomeIcon>
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                    onChange={ (event) => setOneTimeCode(event.target.value) }
                    placeholder="One time code"
                    aria-label="One time code"
                    aria-describedby="basic-addon1"
                    value={oneTimeCode}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                    type="password"
                    onChange={ (event) => setPassword(event.target.value) }
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="basic-addon1"
                    value={password}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                    type="password"
                    onChange={ (event) => setPasswordRepeat(event.target.value) }
                    placeholder="Password Repeat"
                    aria-label="Password Repeat"
                    aria-describedby="basic-addon1"
                    value={passwordRepeat}
                    />
                </InputGroup>
    
                <Button variant="primary" className="loginButton" disabled={buttonDisabled} onClick={() => {
                    patchPassword();
                }}>Set password</Button>
            </div>
        )
    }
}

export default ResetPasswordPage;