import jwt_decode from 'jwt-decode';

export function verifyToken(token) {
    try {
        var decoded = jwt_decode(token);

        var currentTimestamp = Date.now();

        if(decoded.iss !== "IOTNett") return false;
        if(decoded.nbf > currentTimestamp) return false;
        if(decoded.exp > currentTimestamp) return false;

        return true;
    } catch(error) {
        return false;
    }
}

export function decodeToken(token) {
    return jwt_decode(token);
}