import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover, ProgressBar } from 'react-bootstrap';
import { faChartLine, faBell, faCogs, faThumbsUp, faThumbsDown, faExclamationTriangle, faUserShield } from '@fortawesome/free-solid-svg-icons';
import './DeviceBar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLastMeasurement, getMeasurements, getMeasurementsWithinRange } from '../../functions/rest';
import ChartDisplay from '../ChartDisplay/ChartDisplay';
import DeviceConfig from '../DeviceConfig/DeviceConfig';
import AlarmConfig from '../AlarmConfig/AlarmConfig';
import DeviceActions from '../DeviceActions/DeviceActions';
const DeviceBar = (props) => {
    const [batteryLevel, setBatteryLevel] = useState(0);
    const [snrLevel, setSnrLevel] = useState(0);
    const [show, setShow] = useState(false);
    const [showDeviceConfig, setShowDeviceConfig] = useState(false);
    const [showDeviceActions, setShowDeviceActions] = useState(false);
    const [showAlarmConfig, setShowAlarmConfig] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [site, setSite] = useState(null);
    const [signalColor, setSignalColor] = useState(null);
    const [minSignalStrength, setMinSignalStrength] = useState(-25);
    const [maxSignalStrength, setMaxSignalStrength] = useState(25);
    const [batteryColor, setBatteryColor] = useState(null);
    const [timestamp, setTimestamp] = useState(null);
    const [signalStrengthText,setSignalStrengthText] = useState(null);

    const user = props.user;

    const openModal = () => {
        refreshChartData();
        setShow(true);
    }

    const refreshChartData = (startTime, endTime) => {
        if(startTime !== undefined && endTime !== undefined) {
            getMeasurementsWithinRange(props.device.devId, user.token, startTime.getTime(), endTime.getTime()).then(result => {
                if( result === null || result[0] === null || result[0]["series"] === null || result[0]["series"][0] === null) {
                    return
                }
                setChartData(result[0]["series"][0]);
            });
        } else {
            getMeasurements(props.device.devId, user.token, 1).then(result => {
                if( result === null || result[0] === null || result[0]["series"] === null || result[0]["series"][0] === null) {
                    return
                }
                setChartData(result[0]["series"][0]);
            });
        }
    }

    // Fetch last measurements for the device
    useEffect(() => {
        if(props.device === null) return;
        user.sites.filter(site => site.id === props.device.siteId).map(site =>
            setSite(site.name)
        );
        getLastMeasurement(props.device.devId, user.token).then(result => {
            let counter = 0;
            const values = [];
            if( result === null || result[0] === null || result[0]["series"] === null || result[0]["series"][0] === null) {
                return
            }
            result[0]["series"][0]["columns"].map(column => {
                let obj = {
                    "key": column,
                    "value": result[0]["series"][0]["values"][0][counter]
                }
                values.push(obj);
                counter++;
                return column;
            });
            values.map(value => {
                if(
                    value.key === "battery" || 
                    value.key === "digital_out_3" ||
                    value.key === "BatV" ||
                    value.key === "voltage"
                ) {
                    if(value.value !== 0) {
                        if(value.value >= 3.0) setBatteryColor("success")
                        else if(value.value < 2.5) setBatteryColor("danger")
                        else setBatteryColor("warning");
                    }
                    setBatteryLevel(value.value);
                } else if(value.key.startsWith("gateway_snr") &&
                value.value !== null) {
                    setSnrLevel(value.value);
                    if(value.value !== 0) {
                        if(value.value > 0) setSignalColor("success")
                        else if(value.value < -17) setSignalColor("danger")
                        else setSignalColor("warning");
                    }
                } else if(value.key === "time") {
                    setTimestamp(new Date(value.value).getTime());
                } else if(value.key === "battery_ok") {
                    if(value.value) {
                        setBatteryLevel(3.5);
                        setBatteryColor("success")
                    } else {
                        setBatteryLevel(2.5);
                        setBatteryColor("danger")
                    }
                }
                return value;
            });
            if(props.device.appId === "nbsn95" || props.device.appId === "efento-th") {
                setMinSignalStrength(0);
                setMaxSignalStrength(31);
                values.filter(value => 
                    value.key === "signal" &&
                    value.value !== null)
                .map(value => {
                    setSnrLevel(value.value);
                    setSignalStrengthText(value.value);
                    if(value.value !== 0) {
                        if(value.value > 15) setSignalColor("success")
                        else if(value.value < 6) setSignalColor("danger")
                        else setSignalColor("warning");
                    }
                    return value;
                });
            } else {
                values.filter(value => 
                    value.key.startsWith("gateway_snr") &&
                    value.value !== null)
                .map(value => {
                    setSnrLevel(value.value);
                    setSignalStrengthText(value.value + "dB");
                    if(value.value !== null) {
                        if(value.value > 0) setSignalColor("success")
                        else if(value.value < -17) setSignalColor("danger")
                        else setSignalColor("warning");
                    }
                    return value;
                });
            } 
        }   
        );
    }, [props.device, user]);

    const popover = (
        <Popover id="popover-basic">
          <Popover.Title as="h3">Last reported status</Popover.Title>
          <Popover.Content className="centerContent">
            {new Date(timestamp).toLocaleString("no")}
          </Popover.Content>
        </Popover>
    );

    const popoverFalse = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Last reported status</Popover.Title>
        <Popover.Content className="centerContent">
            <div className={"popoverAddition"}>
                {new Date(timestamp).toLocaleString("no")}
            </div>
        </Popover.Content>
    </Popover>
    );

    const popoverWarningTriangle = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Old data is being displayed</Popover.Title>
            <Popover.Content className="centerContent">
                <div className={"popoverAddition"}>
                    This device has failed to report its values for more than 24 hours. Any values displayed on this page are from the last time the device sent data
                </div>
            </Popover.Content>
        </Popover>
        );

    if(props.device === null) {
        return (
            <div className="devicebarContainerHeader">
                <div className="devId">Dev ID</div>
                <div className="hardwareSerial">Hardware Serial</div>
                <div className="friendlyName">Friendly name</div>
                <div className="timestamp">Stamp</div>
                <div className="signalStrength">Signal strength</div>
                <div className="signalStrengthText"></div>
                <div className="batteryLevel">Battery level</div>
                <div className="batteryLevelText"></div>
                <div className="graph">Graph</div>
                <div className="site">Site</div>
                <div className="tools">Tools</div>
            </div>
        )
    }
    return (
        <div className="devicebarContainer">
            <div className="devId">{props.device.devId} {timestamp < (Date.now() - ((24*60*60*1000)*1)) ? <OverlayTrigger trigger={["hover","focus"]} placement="top" overlay={popoverWarningTriangle}><FontAwesomeIcon className={"warningTriangle"} icon={faExclamationTriangle} /></OverlayTrigger> : ""}</div>
            <div className="hardwareSerial">{props.device.hardwareSerial}</div>
            <div className="friendlyName">{props.device.friendlyName}</div>
            <div className="timestamp">
                {
                    timestamp !== null && timestamp > (Date.now() - ((24*60*60*1000)*1)) ? <OverlayTrigger trigger={["hover","focus"]} placement="top" overlay={popover}><FontAwesomeIcon icon={faThumbsUp} className="green" /></OverlayTrigger> : <OverlayTrigger trigger={["hover","focus"]} placement="top" overlay={popoverFalse}><FontAwesomeIcon className="red" icon={faThumbsDown} /></OverlayTrigger>
                }
            </div>
            <div className="signalStrength">{
                snrLevel !== 0 ? <ProgressBar striped variant={signalColor} min={minSignalStrength} max={maxSignalStrength} now={snrLevel} /> : "No data found"
            }
            </div>
            <div className="signalStrengthText">{signalStrengthText}</div>
            <div className="batteryLevel">{
                batteryLevel !== 0 ? <ProgressBar striped variant={batteryColor} max={3.5} min={2.3} now={batteryLevel} /> : "No data found"
            }
            </div>
            <div className="batteryLevelText">{batteryLevel + "V"}</div>
            <div className="graph"><FontAwesomeIcon icon={faChartLine} onClick={() => openModal()}/></div>
            <div className="site">{site}</div>
            <div className="tools">
                <FontAwesomeIcon className="margin" icon={faBell} onClick={() => setShowAlarmConfig(true)}/>
                <FontAwesomeIcon className="margin" icon={faCogs} onClick={() => setShowDeviceConfig(true)}/>
                <FontAwesomeIcon className="margin" icon={faUserShield} onClick={() => setShowDeviceActions(true)}/>
            </div>
            <ChartDisplay visible={show} close={() => setShow(false)} chartData={chartData} device={props.device} refreshChartData={refreshChartData} />
            <DeviceConfig device={props.device} visible={showDeviceConfig} token={user.token} close={() => setShowDeviceConfig(false)}/>
            <AlarmConfig device={props.device} visible={showAlarmConfig} token={user.token} close={() => setShowAlarmConfig(false)} />
            <DeviceActions device={props.device} visible={showDeviceActions} token={user.token} close={() => setShowDeviceActions(false)} />
        </div>
    );
}

export default DeviceBar;