import React from 'react';
import { useSelector } from 'react-redux';

import './Main.css';
import topLogo from './LogoIconCropped.png'
import Devices from '../Devices/Devices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faUser } from '@fortawesome/free-solid-svg-icons';
import CustomerBar from '../../components/CustomerBar/CustomerBar';
import { Link, Route, Switch } from 'react-router-dom';
import User from '../User/User';
import Logout from '../Logout';

const selectUser = state => state.User;
const selectFiles = state => state.Files;

const Main = () => {
    const user = useSelector(selectUser);
    const files = useSelector(selectFiles);

    return (
        <div className="main">
            <div className="topBar">
                <div className="logoContainer">
                    <div className="logo">
                        <img src={topLogo} alt="top logo"></img>
                    </div>
                </div>
                <div className="logoTextContainer">
                    <div className="logoText">IOTNett Customer Web</div>
                </div>
                <div className="customerDisplay">
                    <CustomerBar user={user} fileList={files.fileList} />
                </div>
            </div>
            <div className="contentHolder">
                <div className="sideMenu">
                    <div className="sideMenuContainer">
                        <div className="sideMenuHeader">Menu</div>
                        <div className="sideMenuItems">
                            <div>
                                <FontAwesomeIcon className="icon" icon={faLayerGroup} />
                                <Link className="link" to="/devices">Devices</Link>
                            </div>
                            <div>
                                <FontAwesomeIcon className="icon" icon={faUser} />
                                <Link className="link" to="/user">User</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page">
                <Switch>
                    <Route exact path="/" component={Devices}></Route>
                    <Route path="/user" component={User}></Route>
                    <Route path="/devices" component={Devices}></Route>
                    <Route path="/logout" component={Logout}></Route>
                </Switch>
                </div>
            </div>
        </div>
    )
}

export default Main;