import React from 'react'
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useCurrentPng } from 'recharts-to-png';
import PDFModal from '../PDFModal/PDFModal';
import StatisticsCard from '../StatisticsCard/StatisticsCard';

const EfentoChart = (props) => {
    const temperatureData = [];
    const humidityData = [];

    const temperatureStatistics = {
        pointCounter: 0,
        totalValue: null,
        max: null,
        min: null
    };
    const humidityStatistics = {
        pointCounter: 0,
        totalValue: null,
        max: null,
        min: null
    };

    const [getTemperaturePng, { ref: tempChart }] = useCurrentPng();
    const [getHumidityPng, { ref: humidityChart }] = useCurrentPng();

    props.chartData["values"].map(value => {

        var temp = value[props.chartData["columns"].indexOf("temperature")];
        var humidity = value[props.chartData["columns"].indexOf("humidity")];

        var temperatureObject = {
            Time: value[0],
            Temperature: temp
        }
        var humidityObject = {
            Time: value[0],
            Humidity: humidity
        }
        if(temperatureObject.Temperature != null) temperatureData.push(temperatureObject);
        if(humidityObject.Humidity != null) humidityData.push(humidityObject);

        temperatureStatistics["pointCounter"] = temperatureStatistics["pointCounter"] + 1;
        temperatureStatistics["totalValue"] = temperatureStatistics["totalValue"] + temp;
        if(temperatureStatistics["max"] == null || temperatureStatistics["max"] < temp) temperatureStatistics["max"] = temp;
        if(temperatureStatistics["min"] == null || temperatureStatistics["min"] > temp) temperatureStatistics["min"] = temp;

        humidityStatistics["pointCounter"] = humidityStatistics["pointCounter"] + 1;
        humidityStatistics["totalValue"] = humidityStatistics["totalValue"] + humidity;
        if(humidityStatistics["max"] == null || humidityStatistics["max"] < temp) humidityStatistics["max"] = humidity;
        if(humidityStatistics["min"] == null || humidityStatistics["min"] > temp) humidityStatistics["min"] = humidity;

        return value;
    });
    return (
        <div>
            <PDFModal title="Export PDF" startTime={props.pdfStartTime} endTime={props.pdfEndTime} friendlyName={props.device.friendlyName} images={[
                {
                    key: "Temperature",
                    image: getTemperaturePng,
                    data: temperatureData
                },
                {
                    key: "Humidity",
                    image: getHumidityPng,
                    data: humidityData
                }
                ]} data={[temperatureData, humidityData]} />
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    ref={tempChart}
                    data={temperatureData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                    <XAxis dataKey={"Time"}></XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Temperature" type="monotone" dataKey={"Temperature"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <StatisticsCard
            header={"Temperature Statistics"}
            min={temperatureStatistics["min"]}
            max={temperatureStatistics["max"]}
            points={temperatureStatistics["pointCounter"]}
            average={temperatureStatistics["totalValue"]/temperatureStatistics["pointCounter"]}
            />
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    ref={humidityChart}
                    data={humidityData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}

                >
                    <XAxis dataKey={"Time"}></XAxis>
                    <YAxis type="number" domain={[0, 100]}/>
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Humidity" type="monotone" dataKey={"Humidity"} stroke="#ff7300" yAxisId={0} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <StatisticsCard
            header={"External Temperature Statistics"}
            min={humidityStatistics["min"]}
            max={humidityStatistics["max"]}
            points={humidityStatistics["pointCounter"]}
            average={humidityStatistics["totalValue"]/humidityStatistics["pointCounter"]}
            />
        </div>
    );
}
export default EfentoChart;