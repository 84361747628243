import { faIdCard, faMicrochip, faSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { patchDevice } from '../../functions/rest';
import { useToasts } from 'react-toast-notifications';

const DeviceConfig = (props) => {
    const [device] = useState(props.device);
    const [deviceFriendlyName, setDeviceFriendlyName] = useState(props.device.friendlyName);
    const [deviceAlarmEnabled, setDeviceAlarmEnabled] = useState(props.device.alarmEnabled);
    const [disableSaveButton, setDisableSaveButton] = useState(true);

    const { addToast } = useToasts();

    const newFriendlyName = (newName) => {
        setDeviceFriendlyName(newName);
    }
    const alarmChanged = () => {
        setDeviceAlarmEnabled(!deviceAlarmEnabled);
    }

    useEffect(() => {
        if(
            deviceFriendlyName === props.device.friendlyName &&
            deviceAlarmEnabled === props.device.alarmEnabled
        ) {
            setDisableSaveButton(true);
        } else {
            setDisableSaveButton(false);
        }
    }, [deviceFriendlyName, deviceAlarmEnabled, props.device.friendlyName, props.device.alarmEnabled]);

    const handleChanges = () => {
        device.alarmEnabled = deviceAlarmEnabled;
        device.friendlyName = deviceFriendlyName;
        patchDevice(props.token, device).then((result) => {
            if(result) {
                addToast("Successfully patched device", { appearance: "success" });
                props.close();
            } else {
                addToast("Failed to patch device", { appearance: "error" });
            }
        });
    }

    return (
        <Modal size="lg" show={props.visible} onHide={props.close}>
            <Modal.Header closeButton>
            <Modal.Title>{device.friendlyName} ({device.hardwareSerial}) - Configuration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faIdCard}></FontAwesomeIcon>
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                    placeholder="Device ID"
                    aria-label="Device ID"
                    aria-describedby="basic-addon1"
                    value={device.devId}
                    disabled={true}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon>
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                    placeholder="Hardware serial"
                    aria-label="Hardware serial"
                    aria-describedby="basic-addon1"
                    value={device.hardwareSerial}
                    disabled={true}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faSignature}></FontAwesomeIcon>
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                    onChange={ (event) => newFriendlyName(event.target.value) }
                    placeholder="Device Friendly Name"
                    aria-label="Device Friendly Name"
                    aria-describedby="basic-addon2"
                    value={deviceFriendlyName}
                    />
                </InputGroup>
                <Form.Switch 
                    label={deviceAlarmEnabled ? "Alarm enabled" : "Alarm disabled"}
                    id="alarmSwitch"
                    checked={deviceAlarmEnabled}
                    onChange={() => alarmChanged()}
                />
            </div>
            </Modal.Body>
            <Modal.Footer>
            <Button disabled={disableSaveButton} variant="success" onClick={handleChanges}>
                Save
            </Button>
            <Button variant="secondary" onClick={props.close}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default DeviceConfig;