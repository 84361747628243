import User from './User'
import Files from './Files'
import Devices from './Devices'

const reducers = {
    User,
    Files,
    Devices
}

export default reducers;